import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../../components/PageWrapper";
import styled from "styled-components";
import SEO from "../../../components/seo";
import BackButton from "../../../components/Core/BackButton";
import PageTitle from "../../../components/Core/PageTitle";

const Content = styled.div`

    $ a:hover {
        color: silver;
    }

    & h2 {
        margin: 1.5rem 0;
    }
`

const BlogArticle = ({data}) => {
    const headerConfig = {
        paddingBottom: 10,
    };

    return (
        <>  
            <PageWrapper headerConfig={headerConfig}>
                <SEO title="COFRA Holding" />
                <Content>
                    <BackButton />
                    <Container className="">
                        <Row className="">
                            <Col className="col-lg-2"></Col>
                            <Col className="col-lg-6">
                                <PageTitle
                                    title="COFRA Holding"
                                    align="left"
                                />
                            </Col>
                        </Row>
                        <Row className="">
                            <Col className="col-lg-2 text-right">
                                <div className="mb-4 border-top pt-7">
                                    {/* COFRA Group */}
                                </div>
                                <div>
                                    {/* Last updated<br />
                                    2 March, 2022 */}
                                </div>
                            </Col>
                            <Col className="col-lg-6">
                                <StaticImage
                                    src="img_cofra_holding_logo_brown_bg_landscape.jpg"
                                    alt="COFRA Holding logo"
                                    placeholder="blurred"
                                    layout="fixed"
                                    width={480}
                                    style={{maxWidth: '100%'}}
                                    className="mb-10"
                                />
                                <p>
                                    Founded in 1841, we are a sixth-generation family enterprise with a diversified and international portfolio of businesses and investments across sectors including retail, real estate, renewable energy, private equity and asset management – with the central mission of being a force for good.
                                </p>
                                <p>
                                    We are committed to contribute to the key systems transitions leading to a liveable planet and an equitable society. At COFRA, we recognise the power of business to have a positive impact on our rapidly changing world. Together, we want to enable and fuel positive change. And to do so in a way that addresses the threats to our “global commons” – the balance of climate, water, biodiversity, ocean and land that regulates the stability and resilience of our planet – including cities, energy, capital, food, as well as production and consumption.
                                </p>
                                <p>
                                    The challenges involved in a just transition to fairer, safer and more sustainable industries and societies cannot be tackled alone. This means partnering with forward-thinking investors and business leaders to maximise the impact of combined capital, capabilities and aspirations.
                                </p>
                                <p>
                                    As a holding organisation, we want to partner with our businesses to create and share value. Collectively.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </Content>
            </PageWrapper>
        </>
    )
}

export default BlogArticle;